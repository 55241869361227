import React from "react";
import PropTypes from "prop-types";
import Layout from "@app-components/layout/";
import { css } from "@emotion/core";
import Header from "@app-components/ui/header/top_bottom/";
import line from "@media/svg/squiggly_line.svg";
import Stock4 from "@media/aero/s4.jpg";
import Stock1 from "@media/aero/s1.jpg";
import IFE from "@media/aero/ife_lab.svg";
import Ground from "@media/aero/on_the_ground.svg";

import Link from "gatsby-link";

const Line = ({ reversed = false }) => {
  return (
    <div
      className="mx-auto max-width-3 mb1"
      css={css`
      padding-left: 22px;
      padding-right: 22px;


      @media (max-width: 639px) {
        text-align: right;
      }

      // @media (max-width: 54em) {
      //   text-align:  ${reversed ? "left" : "right"};
      // }
     
    `}
    >
      <img
        alt="Close"
        src={line}
        css={css`
          width: 390px;
          -webkit-transform: ${reversed ? "scaleX(-1)" : "none"};
          transform: ${reversed ? "scaleX(-1)" : "none"};

          @media (max-width: 1023px) {
            width: 60%;
            margin-left: 65px;
            -webkit-transform: none;
            transform: none;
          }

          @media (max-width: 639px) {
            width: 50%;
            -webkit-transform: none;
            transform: none;
          }

          // @media (max-width: 54em) {
          //   width: 190px;
          // }
        `}
      />
    </div>
  );
};

Line.propTypes = {
  reversed: PropTypes.bool.isRequired
};

const Home = () => (
  <Layout page="home">
    <Header
      title={
        <h1 style={{ fontWeight: "300" }}>
          Reinventing passenger entertainment in the air and on the ground
        </h1>
      }
      text="CineSend is a leading provider of content delivery services across the film and television industry, and CineSend Aero is now ready for takeoff."
    />

    <div
      css={css`
        margin-top: -64px;
      `}
    >
      <div class="flex split">
        <div class="col-6 flex justify-center items-center">
          <div class="split-inner px4">
            <img
              src={IFE}
              className="mb2"
              css={css`
                width: 50px;
              `}
            />
            <h2
              className="mb2"
              css={css`
                font-size: 2.2em !important;
                font-weight: 400 !important;
              `}
            >
              Automate IFE Media Workflows
            </h2>
            <p
              class="mb2"
              css={css`
                font-size: 2em !important;
                line-height: 1.25em;
              `}
            >
              Move your content operations into the cloud and away from a
              high-touch, high-cost outsourced lab environment.
            </p>
            <Link
              to="/content-lab"
              className="section-link flex items-center"
              css={css`
                font-size: 1.2em;
                font-weight: 700;
              `}
            >
              <span>Learn more about IFE Content Lab</span>
              <i class="material-icons ml1">arrow_forward</i>
            </Link>
          </div>
        </div>
        <div
          class="col-6 bg relative"
          style={{ backgroundImage: `url(${Stock4})` }}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 100%
              );
            `}
          />
        </div>
      </div>
      <div
        css={css`
          border-bottom: 1px solid #e1e3e4;
        `}
      ></div>

      <div class="flex split">
        <div
          class="col-6 bg relative"
          style={{ backgroundImage: `url(${Stock1})` }}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 100%
              );
            `}
          />
        </div>
        <div class="col-6 flex justify-center items-center">
          <div class="split-inner px4">
            <img
              src={Ground}
              className="mb2"
              css={css`
                width: 50px;
              `}
            />
            <h2
              className="mb2"
              css={css`
                font-size: 2.2em !important;
                font-weight: 400 !important;
              `}
            >
              Start the customer content journey before the flight
            </h2>
            <p
              class="mb2"
              css={css`
                font-size: 2em !important;
                line-height: 1.25em;
              `}
            >
              Extend the customer journey by offering entertainment options to
              passengers on their personal devices in lounges or outside the
              airport environment.
            </p>
            <Link
              to="/on-the-ground"
              className="section-link flex items-center"
              css={css`
                font-size: 1.2em;
                font-weight: 700;
              `}
            >
              <span>Learn more about On The Ground</span>
              <i class="material-icons ml1">arrow_forward</i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

Home.propTypes = {};

export default Home;
